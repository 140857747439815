export interface DigitalServiceConfiguration {
    id: string;
    type: DigitalServiceConfigurationType;
    trialPeriod: boolean;
    trialPeriodMonths: number;
    trialPeriodAvailability: DigitalServiceTrialPeriodAvailability;
    trialPeriodPlanId: string;
    trialPeriodAddOnIds: string[];
    activationMode: DigitalServiceActivationMode;
}

export enum DigitalServiceConfigurationType {
    CUSTOMER = 'CUSTOMER',
    PARTNER = 'PARTNER'
}

export enum DigitalServiceActivationMode {
    BY_CUSTOMER = 'BY_CUSTOMER',
    BY_PARTNER = 'BY_PARTNER',
    BY_THING = 'BY_THING'
}

export enum DigitalServiceTrialPeriodAvailability {
    FIRST_ACTIVATION_ONLY = 'FIRST_ACTIVATION_ONLY',
    ANY_ACTIVATION = 'ANY_ACTIVATION'
}