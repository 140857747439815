export interface ProductModel {
    id: string;
    name: string;
    description: string;
    categoryId: string;
    imageUrl: string;
    thingDefinitionId: string;
    technicalDescription: string;
    technicalSchemes: ProductModelTechnicalScheme[];
}

export interface ProductModelTechnicalScheme {
    name: string;
    description: string;
    imageUrl: string;
    contentType: ProductModelTechnicalSchemeContentType;
    code: string;
    previewUrl: string;
}

export enum ProductModelTechnicalSchemeContentType {
    PNG = "PNG",
    SVG = "SVG"
}