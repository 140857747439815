import { AlertSeverity } from "./alert-definition";
import { PublishTimeUnit } from "./api-key";

export interface Tenant {
    id: string;
    name: string;
    servicePurchaseBackOfficeApprovalEnabled: boolean;
    maxInactivityMonths: number;
    defaultLoginPage: string;
    scoreRecaptchaThreshold: number;
    denyAllUserAuthorization: boolean;
    selfRegistrationEnabled: boolean;
    selfRegistrationDefaultLocationCreationEnabled: boolean;
    selfRegistrationCustomerTypes: TenantCustomerType[];
    customerVerification: boolean;
    domainAliases: string[];
    domainAliasesSslCertificatePresent: boolean;
    domainAliasesSslCertificateKeyPresent: boolean;
    domainAliasesSslCertificateIssues: SslCertificateIssue[];
    domainAliasesSslCertificateExpirationTimestamp: number;
    domainAliasesSslCertificateMode: SslCertificateMode;
    trial: boolean;
    billed: boolean;
    emulatorsEnabled: boolean;
    maxPublishCount: number;
    maxPublishTimeUnit: PublishTimeUnit;
    maxApiKeyRequestCount: number;
    maxApiKeyRequestTimeUnit: PublishTimeUnit;
    alertEvaluationInterval: number;
    defaultMinimumAlertEmailSeverity: AlertSeverity;
    workSessionEvaluationDelay: number;
    workSessionEvaluationWindow: number;
    rawDataRetentionMonths: number;
    nullValueEnabled: boolean;
    defaultNullValue: string;
    auditEnabled: boolean;
    auditRetentionTtl: number;
    auditViewPageEventsEnabled: boolean;
    timestampOmittedInControlPayloads: boolean;
    maxParameterCountPerMessage: number;
    languageSelectorEnabled: boolean;
    enableFeedbackPageLink: boolean;
    feedbackRecipients: string[];
    defaultSenderEmailAddress: string;
    thingCloudExpireDateEnabled: boolean;
    thingActivationTokenRequired: boolean;
    forceMaxOneTagPerThing: boolean;
    thingDefinitionAlgorithmsMinComputationPeriod: ThingDefinitionAlgorithmMinComputationPeriod;
    uiComponentsDraftEnabled: boolean;
    accountSuspensionOnLoginFailuresEnabled: boolean;
    forcedUserLoginIntervalDays: number;
    forcedUserLoginEnabled: boolean;
    inactiveAccountSuspensionEnabled: boolean;
    userPasswordPublicInformationCheckEnabled: boolean;
    userPasswordDictionaryCheckEnabled: boolean;
    customerDataOwnershipEnabled: boolean;
    propertyEvaluationInterval: number;
    connectivityEvaluationInterval: number;
    derivationEvaluationInterval: number;
    alertEvaluationPolicy: string;
    listRangeSelectionMode: ListRangeSelectionMode;
    maxVariablesPerComputedMetric: number;
    normalizedDerivationEvaluationInterval: number;
    partnerSelfRegistrationEnabled: boolean;
    maxHistoricalAlertsAge: number;
    thingInventoryManagement: ThingInventoryManagementType;
    refreshTokenDuration: string;
    continuousEventEvaluationInterval: number;
    maxEventsWithContinuousComputation: number;
    maxPropertiesLength: number;
    enableContinuousEventComputation: boolean;
    newComponentsRepositoryEnabled: boolean;
    languages: string[];
    maxThingCount: number;
    maxCustomerCount: number;
    maxPartnerCount: number;
    maxOrganizationCount: number;
    maxUserCount: number;
    thingCount: number;
    customerCount: number;
    partnerCount: number;
    organizationCount: number;
    userCount: number;
    digitalServicesEnabled: boolean;
    workSessionRetentionMonths: number;
    otherEventRetentionMonths: number;
}

export enum ThingDefinitionAlgorithmMinComputationPeriod {
    HOURS_1 = 'HOURS_1',
    DAYS_1 = 'DAYS_1'
}

export enum TenantCustomerType {
    BUSINESS = 'BUSINESS',
    PERSONAL = 'PERSONAL',
    PARTNER = 'PARTNER'
}

export class SslCertificateIssue {
    type: SslCertificateIssueType;
    details: string;
}

export enum SslCertificateIssueType {
    UNAVAILABLE_CERTIFICATE = 'UNAVAILABLE_CERTIFICATE',
    EMPTY_CERTIFICATE = 'EMPTY_CERTIFICATE',
    INVALID_CERTIFICATE = 'INVALID_CERTIFICATE',
    UNAVAILABLE_KEY = 'UNAVAILABLE_KEY',
    EMPTY_KEY = 'EMPTY_KEY',
    INVALID_KEY = 'INVALID_KEY',
    UNMATCHING_CERTIFICATE_KEY = 'UNMATCHING_CERTIFICATE_KEY',
    EXPIRED_CERTIFICATE = 'EXPIRED_CERTIFICATE',
    NOT_YET_VALID_CERTIFICATE = 'NOT_YET_VALID_CERTIFICATE',
    UNMATCHING_DOMAIN = 'UNMATCHING_DOMAIN',
    UNEXPECTED_ERROR = 'UNEXPECTED_ERROR'
}

export enum SslCertificateMode {
    IMPORT = 'IMPORT',
    FREE = 'FREE',
    PROXY = 'PROXY'
}

export enum ListRangeSelectionMode {
    ITEMS = 'ITEMS',
    PAGES = 'PAGES'
}

export enum ThingInventoryManagementType {
    BY_THING_DEFINITION = 'BY_THING_DEFINITION',
    BY_THING_DEFINITION_AND_MODEL = 'BY_THING_DEFINITION_AND_MODEL',
    BY_MODEL = 'BY_MODEL'
}